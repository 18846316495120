import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'
import teaPic1 from '../../images/solution-tea1.jpg'
import teaPic2 from '../../images/solution-tea2.jpg'
import teaPic3 from '../../images/solution-tea3.jpg'
import teaPic4 from '../../images/solution-tea4.jpg'
import teaPic5 from '../../images/solution-tea5.jpg'
import teaPic6 from '../../images/solution-tea6.jpg'
import teaPic7 from '../../images/solution-tea7.jpg'
import teaPic8 from '../../images/solution-tea8.jpg'

export default class SolutionList extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={3}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='茶纹云-茶叶产业平台' />
        <div className='core-tech-wrap solution-wrap'>
          <div className='main-img'></div>

          <div className='main-con' style={{ paddingBottom: '0px' }}>
            <h2>平台概述</h2>
            <p className='description-text' style={{ marginBottom: '30px' }}>
              “茶纹云”是“数耘”农业产业数字化平台在茶叶产业的重要落地践行，为茶行业打造了全链条的数字化及溯源管理平台，包括数字茶园、数字茶厂、数字茶仓、茶叶营销和渠道数字化管理。截止到2021年4月，“茶纹云”上已经溯源了超过250万饼80个茶企的700多种茶叶产品，实现茶行业溯源的重大突破。“茶纹云”利用人工智能自动识别每一个摄像头的关键操作时机，自动为每一天的茶饼生产制作生产过程短视频，短短“30秒”，就能让消费者“看到”这饼茶的制作过程，帮助企业整合提升普洱茶的品牌形象，切实打通了消费者与生产者的信息壁垒，让消费者真正了解到每一款普洱茶产品是怎么来的，使消费者购买普洱茶产品从基于对“人”的信任逐渐转变为对产品的信任。
            </p>

            <div className='trace-source'>
              <div className='tea-pic-list'>
                <img src={teaPic1} />
              </div>
              <div className='tea-pic-list'>
                <img src={teaPic2} />
              </div>
              <div className='tea-pic-list'>
                <img src={teaPic3} />
              </div>
              <div className='tea-pic-list'>
                <img src={teaPic4} />
              </div>
            </div>
          </div>

          <div className='main-con'>
            <h2>平台架构图</h2>
            <div className='trace-source'>
              <img src={teaPic5} alt='平台架构图' />
            </div>
          </div>
        </div>
        <div className='core-tech-wrap solution-wrap'>
          <img src={teaPic6} />
        </div>
        <div className='core-tech-wrap solution-wrap solution-module'>
          <div className='main-con'>
            <div className='trace-source'>
              <img src={teaPic7} />
            </div>
          </div>
        </div>
        <div className='core-tech-wrap solution-wrap'>
          <img src={teaPic8} />
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      solutions {
        id
        title
        image_link
        short_description
      }
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
